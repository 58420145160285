import React from "react";
import Badges from "../../../commons/components/Badges";
import { RowContent } from "../../../commons/components/RowContent";
import { FullWideImage } from "../../../commons/components/FullWideImage";

export const WineTrailPage = () => {

    return (
        <div className="page_content_wrap scheme_default">
            <div className="wrap">
                <RowContent icon={"icon-grape-leafs"}
                            paragraphAsDiv
                            title={"Zapraszamy od majówki"}
                            subtitle={"Już od maja otwieramy sezon letni, podczas którego będziemy częstować naszych gości regionalnym winem :) "}
                            paragraphs={[
                                'Ogród winny przy winnicy - czynny w każdy piątek, sobotę i niedzielę w godzinach od 12 do 20.',
                                'Sklep przy winnicy z naszymi winami na wynos - czynny w pozostałe dni w godzinach od 12 do 18'
                            ]}
                />
                <FullWideImage image={"szlakwinny2.jpg"} padding={{paddingBottom: "50px"}}/>
                <RowContent icon={"icon-grape-leafs"}
                            title={"Z kieliszkiem wina"}
                            subtitle={"W sezonie letnim w naszej winiarni możesz kupić kieliszek wina i degustować go w dowolnym miejscu."}
                            paragraphs={[
                                "Do wyboru masz wiele możliwości. Możesz usiąść w altanie, przy stoliku pod gołym niebem, spacerować lub nawet zabrać leżak w dowolne miejsce i wypocząć w towarzystwie wina i winorośli, z których powstało.",
                                "Oczywiście możesz również kupić butelkę na wynos w naszym sklepie."
                            ]}
                />
                <FullWideImage image={"szlakwinny-altana.jpg"} padding={{paddingBottom: "50px"}} />
                <RowContent icon={"icon-grape-leafs"}
                            title={"Dostępność"}
                            subtitle={"Z przejścia przez winnicę można skorzystać tylko w godzinach otwarcia"}
                            paragraphs={[
                                "Zawsze należy pamiętać o:",
                                "- każdorazowym zamykaniu bramy wejściowej, w celu zabezpieczenia roślin przed zwierzyną leśną.",
                                "- nie zrywaniu liści i gron z winorośli",
                                "- nie wchodzeniu na teren winnicy poza godzinami otwarcia (nasz piesek też lubi sobie po niej pospacerować)"
                            ]}
                />
                <FullWideImage image={"szlakwinny1.jpg"} padding={{paddingBottom: "50px"}} />
            </div>
            <Badges/>
            <FullWideImage image={"szlakwinny3.jpg"} />
        </div>
    )
};