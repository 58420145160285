export const pageStyle = {
    home: {
        mainImage: 'winnica_zachod2.jpg'
    }
} as const

interface IPageSettings {
    dotComponent: {
        wines: [number, number, number, number]
    }
}

export const pageSettings: IPageSettings = {
    dotComponent: {
        wines: [1, 8, 15, 17]
    }
}