import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {CallToAction} from "../../../commons/components/CallToAction";
import {CommonWrapper} from "../../../commons/components/wrappers/CommonWrapper";
import events from "../../../model/data/events.json"
import {Event} from "../EventComponent";
import { RowContent } from "../../../commons/components/RowContent";

export const EventsPage: FC<{}> = () => {

    const {t} = useTranslation();

    const eventPageList: Event[] = events.filter(e => e.archived === false);

    return (
        <div className="page_content_wrap scheme_default">
            <CommonWrapper>
                <div className="row wpb_row">
                    {
                        eventPageList.length ? eventPageList.map(event => {
                            return <CallToAction title={event.title}
                                                 subtitle={event.home ? t('EVENTS.HOME') : t('EVENTS.AWAY')}
                                                 button={{label: t('EVENTS.BUTTON.GO_TO_EVENT'), path: `/wydarzenia/${event.id}`}}
                                                 backgroundImage={event.backgroundImage}
                                                 key={event.id}
                            />
                        })
                            :                 <RowContent icon={""}
                                                          title={""}
                                                          subtitle={''}
                                                          paragraphs={["Brak nadchodzących wydarzeń"]}
                            />
                    }
                </div>
            </CommonWrapper>
            <div className="wpb_column vc_column_container column-12_12">
                <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                        <div
                            className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                            <span className="vc_sep_holder vc_sep_holder_l"> <span className="vc_sep_line"/> </span>
                            <span className="vc_sep_holder vc_sep_holder_r"> <span className="vc_sep_line"/> </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
